<template>
    <div id="empower">
        <div class="mainBtn" v-if="btnDisabled" @click="saveAreaData">授权</div>
        <div class="left-tree">
            <p class="title">用户</p>
            <div class="content scrollbar">
                <el-tree
                    class="userTree line-tree"
                    :data="userTree"
                    show-checkbox
                    default-expand-all
                    node-key="ID"
                    ref="userTree"
                    highlight-current
                    :props="userProps"
                    @node-click="getNodeInfo"
                    @check-change="checkChange"
                    :check-on-click-node="false"
                    :expand-on-click-node="false"
                >
                </el-tree>
            </div>
        </div>
        <div class="right-content">
            <p class="title">村民小组</p>
            <div class="content scrollbar">
                <el-tree
                    class="areaTree"
                    :data="areaTree"
                    show-checkbox
                    :default-checked-keys="checkedArr"
                    :default-expand-all="true"
                    node-key="AREACODE"
                    ref="areaTree"
                    highlight-current
                    :props="defaultProps"
                >
                </el-tree>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "survey",
    data() {
        return {
            userTree: [],
            areaTree: [],
            checkedArr: [],
            userProps: {
                label: "REALNAME",
                children: "CHILDREN",
            },
            defaultProps: {
                label: "NAME",
                children: "CHEILDREN",
            },
            btnDisabled: false,
        };
    },
    mounted() {
        this.getTableData();
        this.getAreaTree();
    },
    methods: {
        ...mapActions([
            "getMJDCUserList",
            "getAreaGroupTree",
            "getUserXzqhbm",
            "insertMjdcXzqhbm",
        ]),
        async getTableData() {
            let res = await this.getMJDCUserList();
            this.userTree = res;
        },
        async getAreaTree() {
            let res = await this.getAreaGroupTree();
            this.areaTree = res;
        },
        async getNodeInfo(data) {
            let res = await this.getUserXzqhbm({
                userId: data.ID,
            });
            this.checkedArr = res;
            this.$nextTick(() => {
                this.$refs.areaTree.setCheckedKeys(this.checkedArr);
            });
        },
        checkChange() {
            this.btnDisabled =
                this.$refs.userTree &&
                this.$refs.userTree.getCheckedKeys().length > 0;
        },
        async saveAreaData() {
            let users = this.$refs.userTree.getCheckedKeys();
            let areas = this.$refs.areaTree.getCheckedKeys(true);
            let res = await this.insertMjdcXzqhbm({
                userId: users.join(","),
                xzqhbm: areas.join(","),
            });
            if (res) {
                this.$message({
                    type: "success",
                    message: "授权成功",
                });
                this.$refs.areaTree.setCheckedKeys([]);
                this.$refs.userTree.setCheckedKeys([]);
                this.$refs.areaTree.setCurrentKey();
                this.$refs.userTree.setCurrentKey();
            } else {
                this.$message({
                    type: "success",
                    message: "授权失败",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
#empower {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    padding-top: 30px !important;
    padding-left: 10px;
    .left-tree {
        overflow-x: hidden;
        width: 35%;
        margin-right: 20px;
        height: calc(100% - 50px);
        .el-tree-node {
            position: relative;
        }
    }
    .mainBtn {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 30px;
        display: inline-block;
        min-width: 80px;
        line-height: 30px;
        text-align: center;
        padding: 0 10px;
        margin-left: 10px;
        background-color: var(--headBgColor);
        color: #fff;
        border-radius: 10px;
        cursor: pointer;
    }
    .right-content {
        width: calc(65% - 35px);
        height: calc(100% - 50px);
    }
    .left-tree,
    .right-content {
        p.title {
            font-size: 16px;
            height: 30px;
            line-height: 30px;
        }
        .content {
            height: calc(100% - 30px);
            border: 1px solid #ccc;
        }
    }
}
</style>
